.sidebar {
  @apply w-full fixed top-0 left-0 bottom-0 z-10 bg-white text-[#71757B] transition-all ease-in-out duration-500  mt-[64px] pb-[51px] h-[calc(100vh-115px)] overflow-y-auto shadow-[4px_0px_10px_-9px_#d6d6d6];
}

.nav-link {
  @apply flex items-center justify-start rounded px-5 py-4 hover:bg-theme/[0.05] hover:text-theme mb-1;
}
.nav-link > span,
.sub-menu-wrapper span {
  @apply truncate font-semibold;
}

/* Active Menu Link */
.sub-menu-wrapper .MuiAccordion-root .MuiButtonBase-root {
  @apply text-[#71757B];
}
.sub-menu-wrapper .MuiAccordion-root .MuiButtonBase-root .MuiAccordionSummary-content {
  @apply my-0;
}
.sidebar li.active > .nav-link,
.sidebar li.active > .MuiButtonBase-root,
.sub-menu-wrapper.active .MuiAccordion-root.li-items .MuiButtonBase-root,
.sub-menu-wrapper.active .MuiAccordionSummary-root {
  @apply bg-theme/10 text-theme;
}

.sub-menu-wrapper .MuiAccordion-root .MuiButtonBase-root.Mui-expanded {
  @apply text-theme;
}

/* Active Sub Menu Link */
.sidebar li.submenu-active > .sub-menu,
.sub-menu-wrapper.active .MuiAccordion-root .MuiButtonBase-root .MuiAccordionSummary-expandIconWrapper,
.sub-menu-wrapper .MuiAccordion-root .MuiButtonBase-root:hover .MuiAccordionSummary-expandIconWrapper,
.sub-menu-wrapper .MuiAccordion-root .MuiButtonBase-root.Mui-expanded .MuiAccordionSummary-expandIconWrapper,
.sidebar-mini-menu .MuiMenu-list .MuiMenuItem-root.submenu-active {
  @apply text-theme;
}

.sub-menu-wrapper .sub-menu {
  @apply my-1 block text-base font-medium hover:text-theme text-[#71757B];
}
.sub-menu-wrapper .MuiAccordion-root {
  @apply mx-0 shadow-none;
}
.sub-menu-wrapper .MuiAccordion-root.li-items .MuiButtonBase-root {
  @apply min-h-[auto] rounded px-5 py-4 hover:bg-theme/10 hover:text-theme;
}
.sub-menu-wrapper .MuiAccordion-root .MuiButtonBase-root {
  @apply rounded hover:bg-theme/10 hover:text-theme;
}

.sub-menu-wrapper .MuiAccordionSummary-root,
.sub-menu-wrapper .MuiAccordionSummary-root.Mui-expanded {
  @apply min-h-[57px];
}

/* Submenu for mini sidebar  */
.sub-menu-wrapper .tooltip-btn {
  @apply min-w-[auto] rounded mb-1 text-[#71757B] hover:bg-theme/10 hover:text-theme;
}

.sub-menu-wrapper .tooltip-btn.tooltip-open {
  @apply bg-theme/10 text-theme;
}
.sidebar-mini-menu .MuiMenu-list {
  @apply p-2 [&>.MuiMenuItem-root:first-child]:pl-0 [&>.MuiMenuItem-root:first-child]:pb-0;
}
.sidebar-mini-menu .MuiMenu-list .MuiMenuItem-root {
  @apply hover:bg-transparent hover:text-theme;
}
