.swal-modal .swal-icon,
.swal-modal .swal-icon.swal-icon--custom {
  @apply w-full max-w-[150px];
}
.swal-modal .swal-button,
.swal-modal .swal-button:not([disabled]):hover {
  @apply bg-theme;
}

.errorAlert .swal-title,
.errorAlert-login .swal-title {
  @apply text-danger tracking-[0.5px];
}

.successAlert .swal-title,
.successAlert-login .swal-title {
  @apply text-[#59cf65] tracking-[0.5px];
}

.swal-modal.errorAlert .swal-footer {
  @apply text-center;
}
.swal-modal.errorAlert .swal-footer .swal-button.swal-button--Cancel {
  @apply text-title-color bg-c-grey-100 disabled:bg-dark-100 disabled:text-title-color hover:bg-c-grey-100;
}
.swal-modal.errorAlert .swal-footer .swal-button.swal-button--OK {
  @apply bg-[#e64942];
}

.swal-title {
  @apply text-xl pt-0;
}
.swal-modal {
  @apply max-w-[350px] min-h-[210px] w-full bg-white rounded;
}
.swal-text {
  @apply text-center;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm.swal-confirm-btn,
div:where(.swal2-container) div:where(.swal2-actions):not(.swal2-loading) .swal2-styled.swal-confirm-btn {
  @apply bg-danger rounded-md hover:bg-none hover:bg-danger border-none shadow-none;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel.swal-cancel-btn,
div:where(.swal2-container) div:where(.swal2-actions):not(.swal2-loading) .swal2-styled.swal-cancel-btn {
  @apply bg-c-grey-100 rounded-md hover:bg-none hover:bg-c-grey-150 text-title-color border-none shadow-none;
}

div:where(.swal2-container) button:where(.swal2-styled) .swal-deny-btn.swal2-deny,
div:where(.swal2-container) div:where(.swal2-actions):not(.swal2-loading) .swal-deny-btn.swal2-styled.swal2-deny {
  @apply rounded-md hover:bg-none border border-solid border-theme text-theme bg-transparent shadow-none;
}
