
.utm-toggel-btn .switch {
  @apply relative inline-block w-[40px] h-[20px];

}

.utm-toggel-btn .switch_Agency input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  transform: translateX(18px);
}

.utm-toggel-btn .switch_Agency .slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 3px;
  background-color: #fff;
  transition: 0.4s;
}
.utm-shifting-icons .delete-btn{
    @apply bg-[#E32833] text-white border-transparent;
}
.utm-shifting-icons .delete-btn:hover{
    @apply border-transparent bg-[#E32833];
}

.utm-shifting-icons .delete-btn:disabled{
  @apply bg-disable/20 text-white border-transparent;
}