.tab-head-wrapper {
  @apply relative;
}

.tab-head-wrapper.disabled .nav-card {
  @apply cursor-not-allowed bg-[#f3f3f3];
}

.tab-head-wrapper.disabled .nav-card,
.tab-head-wrapper.disabled .nav-card .info,
.tab-head-wrapper.disabled .nav-card .title {
  @apply text-dark-400;
}

.tab-head-wrapper:last-child .nav-card {
  @apply border-b-0;
}

.nav-card {
  @apply bg-white text-[#71757B] p-3 lg:p-4 gap-2 flex cursor-pointer md:border-b items-center md:items-start;
}

.nav-card .title {
  @apply text-base text-black font-semibold md:mb-2 whitespace-nowrap;
}

.nav-card .info {
  @apply hidden md:block text-sm text-[#71757B];
}

.tab-head-wrapper.active .nav-card,
.nav-card.active {
  @apply bg-theme/10 text-theme;
}

.tab-head-wrapper.active .nav-card .title,
.nav-card.active .title {
  @apply text-theme;
}

.tab-head-content {
  @apply p-0 w-full w-[220px] sm:w-[270px] max-h-[calc(100vh-165px)] h-full overflow-y-auto flex flex-row md:flex-col z-[2];
  /* @apply p-0 w-full md:w-[220px] xl:w-[270px] max-h-[calc(100vh-165px)] h-full overflow-y-auto sticky top-[64px] md:top-[75px] left-0 right-0 flex flex-row md:flex-col z-[2]; */
}

.tab-head-wrapper.active .nav-card::before,
.nav-card.active::before {
  content: '';
  @apply w-full absolute top-0 left-0 bottom-0 border-t-[3px] border-t-theme md:border-t-0 md:border-t-transparent md:border-l-[3px] md:border-l-theme;
}

.tab-head-wrapper.active .nav-card .info,
.nav-card.active .info {
  @apply text-theme/70;
}