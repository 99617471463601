/* Right section stylings after login */
/* .page-container {
  @apply bg-[#F4F8FF];
} */

.title-breadcrumb-wrapper {
  @apply flex flex-wrap sm:items-center justify-between gap-x-4;
}

.page-title {
  @apply min-w-max text-base sm:text-lg md:text-lg lg:text-2xl font-bold;
}

.page-card {
  @apply bg-white rounded mt-4;
}

.flex-page-filters-bar {
  @apply flex flex-col sm:flex-row items-center justify-between p-4 pb-5 gap-3 sm:gap-4;
}

.grid-page-filters-bar {
  @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-flow-col p-4 pb-5 gap-3 sm:gap-4;
}

.grid-search-bar {
  @apply lg:max-w-[300px] sm:col-span-2;
}

/* Right section stylings after login */