/* .fc-event-title {
  position: relative;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Figtree', sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #2472fc !important;
  padding: 2px 10px !important;
}

.fc-event.fc-event-draggable {
  background-color: #e9f1ff !important;
} */
/* margin: 2px 0 !important; */

/* .fc-event-title::before {
  position: absolute;
  content: ' ';
  top: 0;
  left: 0;
  height: 100%;
  width: 3px;
  background-color: #2472fc;
  border-radius: 2px;
} */

.fc-event-title {
  position: relative;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Figtree', sans-serif;
  font-size: 15px;
  font-weight: 500;
  /* color: #2472fc !important; */
  padding: 2px 4px !important;
}

.calendarEvents button.fc-prev-button,
.calendarEvents button.fc-next-button {
  @apply border-theme bg-white text-theme hover:bg-white hover:text-theme hover:border-theme py-2 px-3;
}

.calendarEvents button.fc-prev-button:active,
.calendarEvents button.fc-next-button:active {
  @apply !px-3 !py-2;
}

.fc .fc-button-group {
  gap: 10px;
}

.fc-toolbar-chunk .fc-toolbar-title {
  @apply font-medium;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  @apply bg-theme border-theme shadow-none py-3 px-5;
}

.fc .fc-button-primary {
  @apply py-2 px-5 border-theme bg-white text-theme hover:bg-white hover:text-theme hover:border-theme;
}

.calendarEvents .fc-h-event {
  @apply bg-green-400;
}

.fc .fc-timegrid-slot {
  height: 2em;
}

.fc .fc-button-primary:focus {
  box-shadow: none !important;
}

.fc .fc-button:focus {
  box-shadow: none !important;
}

.fc-dayGridMonth-button.fc-button,
.fc-timeGridWeek-button.fc-button,
.fc-timeGridDay-button.fc-button,
.fc-listWeek-button.fc-button {
  @apply border-0 border-y border-theme hover:border-theme;
}

.fc-dayGridMonth-button.fc-button {
  @apply border-l border-theme;
}

.fc-listWeek-button.fc-button {
  @apply border-r border-theme;
}

.fc-direction-ltr .fc-button-group>.fc-button:not(:first-child) {
  margin-left: 0;
}

.fc .fc-v-event {
  @apply border-none
}

/* .calendarEvents .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr{
  @apply w-[89%]
} */
.fc .fc-toolbar-chunk .fc-today-button.fc-button-primary:hover {
  background-color: #2c3e50 !important;
  border: 0;
  color: white !important;
}

/* Popover height */
.fc .fc-popover {
  max-height: 50%;
  overflow-y: scroll;
}

.fc .fc-popover-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background: hsl(0deg 0% 82%);
}