/** Apply Job Modal */

.CDashboard1 .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  @apply w-full max-w-[800px];
}

/* Create Job Page */
/* job switch*/
.switch_Agency .slider {
  @apply absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-[#dde0e2] duration-300;
  /* padding: 22px 0 0;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dde0e2;
  transition: 0.4s; */
}

.switch_Agency input:checked + .slider {
  @apply bg-[#2472fc]
  /* background-color: #2472fc; */;
}

.switch_Agency .slider.round {
  @apply rounded-[34px];
  /* border-radius: 34px; */
}

.switch {
  @apply relative inline-block w-[50px] h-[25px];
  /* position: relative;
  display: inline-block;
  width: 60px;
  height: 34px; */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch_Agency input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  transform: translateX(24px);
}

.switch_Agency .slider.round:before {
  @apply rounded-[50%]
  /* border-radius: 50%; */;
}

.switch_Agency .slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3.5px;
  background-color: #fff;
  transition: 0.4s;
}

.double_toogle_button .fixed_toogle_button button.MuiButtonBase-root.Mui-selected.MuiToggleButton {
  width: 100px;
  color: #bfe9ff;
  border-radius: 24px 0px 0px 24px;
  border: none;
}

.double_toogle_button .firstjobPricingTypebtn button.MuiButtonBase-root {
  width: 100px;
  color: #000;
  background-color: #f4f5f8;
  border-radius: 24px 0px 0px 24px;
  border: none;
  font-weight: 600;
}

.double_toogle_button .secondjobPricingTypebtn button.MuiButtonBase-root {
  width: 100px;
  color: #000;
  background-color: #f4f5f8;
  border-radius: 0px 24px 24px 0px;
  border: none;
  font-weight: 600;
}

.double_toogle_button .fixed_toogle_button button.MuiButtonBase-root.MuiToggleButton-root {
  width: 100px;
  color: #133268;
  background-color: #f4f5f8;
  border: none;
  font-weight: 600;
}

.double_toogle_button .first_toogle_button .MuiButtonBase-root {
  background: #2472fc !important;
  border-radius: 24px 0px 0px 24px;
  color: #fff !important;
}

.double_toogle_button .second_toogle_button .MuiButtonBase-root {
  background: #2472fc !important;
  border-radius: 0 30px 30px 0;
  color: #fff !important;
}

.tags-input-container {
  padding: 0;
  border-radius: 3px;
  width: min(80vw, 600px px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 0 3px;
  gap: 0 3px;
}

.tag-item {
  margin: 4px 0 0;
}

.tag-item {
  background-color: rgba(36, 114, 252, 0.08);
  display: flex;
  padding: 1px 5px 0 10px;
  border-radius: 30px;
  font-size: 15px;
  font-weight: 600;
  height: 33px;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.Marketing_2 {
  min-height: 47px;
  background: #f9fbfc;
}

.Marketing_2 {
  padding: 0 9px;
}

.Marketing {
  border: 1px solid #c6cacc;
  width: 100%;
  /* padding: 11px; */
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}

.tag-item span {
  font-size: 15px;
  font-weight: 500;
  font-family: 'Figtree', sans-serif;
  color: #2472fc;
}

.tag-item button.close-tag {
  font-size: 15px;
  font-weight: 500;
  font-family: 'Figtree', sans-serif;
  color: #2472fc;
}

.tag-item .close {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  font-size: 18px;
  cursor: pointer;
}

input.tags-input {
  background: #f9fbfc;
  height: 60px;
}

input.tags-input {
  border: none !important;
}

input.tags-input {
  padding-left: 5px;
}

.tags-input {
  flex-grow: 1;
  padding: 0.5em 0;
  border: none;
  outline: none;
}

.shwo_data h3 {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #000;
  margin: 0 0 20px;
  text-transform: capitalize;
  position: relative;
  line-height: 24px;
  border-bottom: 1px solidhsla(0, 0%, 86.7%, 0.30980392156862746);
  padding: 0 0 9px;
}

/* View Jobs */
.job-custom_popup {
  position: fixed;
  z-index: 1300;
  inset: 0px;
}

.job-custom_popup .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  border-radius: 0 !important;
  margin: 0 !important;
  max-height: 100% !important;
  max-width: 70% !important;
  width: 70% !important;
  float: right !important;
  height: 100% !important;
}

.mediaimg {
  margin-top: 20px;
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.mediaimg div {
  margin-right: 5px;
  /* width: 120px;
  height: 120px; */
  overflow: hidden;
}

/* .mediaimg div {
  width: 10%;
  float: left;
} */

.mediaimg div img {
  width: 120px;
  object-fit: cover;
  object-position: center;
  /* padding: 5px; */
  border-radius: 20px;
}

.job-custom_popup .MuiDialog-container.MuiDialog-scrollPaper {
  display: unset !important;
}

/* .profileImgDialogagencyApprovel .MuiPaper-root.MuiPaper-elevation {
  width: 60%;
  max-width: 60%;
} */

/* .profileImgDialogagency .MuiPaper-root.MuiPaper-elevation {
  width: 600px;
} */

.lastdatesec
  .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  background: #fff;
  border: 1px solid #c6cacc;
  width: 135px;
  height: 62px;
  border-radius: 1px 2px 2px 1px;
  padding: 5px;
}
.lastdatesec .MuiInputBase-root::before{
  border: 0 !important;
}
.lastdatesec input {
  border: none !important;
  padding: 0 !important;
  font-size: 14px;
  text-align: center;
  color: #71757b;
}

.res_lastdatesec .MuiInputBase-root.MuiInput-root {
  @apply h-full;
}

.Companyname .MuiOutlinedInput-root {
  height: 50px !important;
}

.BrowseVaultDialogMedia .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper {
  width: 100%;
  max-width: 75%;
}
