.crouselImageContent {
  @apply pb-6 !important;
}

.viewApprovalEditor .editor-container {
  @apply max-h-[460px] h-[418px];
}
.viewApprovalEditor .editor-container .editor-inner {
  @apply h-[calc(100%_-_45px)] [&>.editor-input]:min-h-full;
}

.comment-box {
  /* @apply relative z-[10000] left-3/4 shadow-lg border rounded w-64 bg-white text-black p-4 top-1/2 -translate-y-1/2; */
  @apply relative z-[10000] left-[5%] shadow-lg border rounded w-64 bg-white text-black p-4;
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 0px 4px);
}

.comment-box.comment-box-left-arrow:after {
  @apply absolute content-["_"] -left-3 top-[calc(50%_-_1.2rem)] w-10 h-10 rotate-45 rounded-[0_0_0_4px];
  background: #ededed linear-gradient(white, white);
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
}
