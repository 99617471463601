.header {
  @apply sticky top-0 right-0 z-20 flex items-center bg-white;
}

.header .toggle-btn {
  @apply w-[22px] h-[22px] cursor-pointer text-[#585858];
}

.loginsigin {
  @apply absolute w-[200px] bg-white p-2 right-0 shadow-[-1px_-1px_7px_#ddd] top-[52px] z-[9999] border-t-[3px] border-theme;
}

.notification-count {
  @apply h-[20px] w-[10px] z-[777] absolute -right-1 bottom-3 rounded-full text-[10px] flex items-center justify-center text-white p-[2px_10px];
}
