.editor .other h2 {
  font-size: 18px;
  color: #444;
  margin-bottom: 7px;
}

.editor .other a {
  color: #777;
  text-decoration: underline;
  font-size: 14px;
}

.editor .other ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.editor .App {
  font-family: sans-serif;
  text-align: center;
}

.editor h1 {
  font-size: 24px;
  color: #333;
}

.editor .ltr {
  text-align: left;
}

.editor .rtl {
  text-align: right;
}

.editor .editor-container {
  /* margin: 20px auto 20px auto; */
  border-radius: 2px;
  /* max-width: 600px; */
  color: #000;
  position: relative;
  line-height: 20px;
  font-weight: 400;
  text-align: left; 
}

.editor .editor-inner {
  background: #fff;
  position: relative;
}

.editor .editor-input {
  min-height: 150px;
  resize: none;
  font-size: 15px;
  caret-color: rgb(5, 5, 5);
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: 12px;
  caret-color: #444;
  cursor: text;
}

.editor .editor-placeholder {
  margin-left: 10px;
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 15px;
  left: 10px;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.editor .editor-text-bold {
  font-weight: bold;
}

.editor .editor-text-italic {
  font-style: italic;
}

.editor .editor-text-underline {
  text-decoration: underline;
}

.editor .editor-text-strikethrough {
  text-decoration: line-through;
}

.editor .editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}

.editor .editor-text-code {
  background-color: rgb(240, 242, 245);
  padding: 1px 0.25rem;
  font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 94%;
}

.editor .editor-link {
  color: rgb(33, 111, 219);
  text-decoration: none;
}

.editor .tree-view-output {
  display: block;
  background: #222;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  white-space: pre-wrap;
  margin: 1px auto 10px auto;
  max-height: 250px;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: auto;
  line-height: 14px;
}

.editor .editor-code {
  background-color: rgb(240, 242, 245);
  font-family: Menlo, Consolas, Monaco, monospace;
  display: block;
  padding: 8px 8px 8px 52px;
  line-height: 1.53;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  tab-size: 2;
  /* white-space: pre; */
  overflow-x: auto;
  position: relative;
}

.editor .editor-code:before {
  content: attr(data-gutter);
  position: absolute;
  background-color: #eee;
  left: 0;
  top: 0;
  border-right: 1px solid #ccc;
  padding: 8px;
  color: #777;
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
}

.editor .editor-code:after {
  content: attr(data-highlight-language);
  top: 0;
  right: 3px;
  padding: 3px;
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
}

.editor .editor-tokenComment {
  color: slategray;
}

.editor .editor-tokenPunctuation {
  color: #999;
}

.editor .editor-tokenProperty {
  color: #905;
}

.editor .editor-tokenSelector {
  color: #690;
}

.editor .editor-tokenOperator {
  color: #9a6e3a;
}

.editor .editor-tokenAttr {
  color: #07a;
}

.editor .editor-tokenVariable {
  color: #e90;
}

.editor .editor-tokenFunction {
  color: #dd4a68;
}

.editor .editor-paragraph {
  margin: 0;
  margin-bottom: 8px;
  margin-left: 8px;
  position: relative;
}

.editor .editor-paragraph:last-child {
  margin-bottom: 0;
}

.editor .editor-heading-h1 {
  font-size: 24px;
  color: rgb(5, 5, 5);
  font-weight: 400;
  margin: 0;
  margin-bottom: 8px;
  margin-left: 8px;
  padding: 0;
}

.editor .editor-heading-h2 {
  font-size: 15px;
  color: rgb(101, 103, 107);
  font-weight: 700;
  margin: 0;
  margin-bottom: 8px;
  margin-left: 8px;
  padding: 0;
  text-transform: uppercase;
}

.editor .editor-quote {
  margin: 0;
  margin-left: 20px;
  font-size: 15px;
  color: rgb(101, 103, 107);
  border-left-color: rgb(206, 208, 212);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}

.editor .editor-list-ol {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.editor .editor-list-ul .editor-listitem {
  list-style-type: disc;
}

.editor .editor-list-ol .editor-listitem {
  list-style-type: decimal;
}

.editor .editor-list-ul {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.editor .editor-listitem {
  margin: 8px 32px 8px 32px;
}

.editor .editor-nested-listitem {
  list-style-type: none;
}

.editor pre::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

.editor pre::-webkit-scrollbar-thumb {
  background: #999;
}

.editor .debug-timetravel-panel {
  overflow: hidden;
  padding: 0 0 10px 0;
  margin: auto;
  display: flex;
}

.editor .debug-timetravel-panel-slider {
  padding: 0;
  flex: 8;
}

.editor .debug-timetravel-panel-button {
  padding: 0;
  border: 0;
  background: none;
  flex: 1;
  color: #fff;
  font-size: 12px;
}

.editor .debug-timetravel-panel-button:hover {
  text-decoration: underline;
}

.editor .debug-timetravel-button {
  border: 0;
  padding: 0;
  font-size: 12px;
  top: 10px;
  right: 15px;
  position: absolute;
  background: none;
  color: #fff;
}

.editor .debug-timetravel-button:hover {
  text-decoration: underline;
}

.editor .emoji {
  color: transparent;
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin: 0 -1px;
}

.editor .emoji-inner {
  padding: 0 0.15em;
}

.editor .emoji-inner::selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.editor .emoji-inner::moz-selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.editor .emoji.happysmile {
  background-image: url(../../../images/emoji/1F642.png);
}

.editor .toolbar {
  position: sticky;
  width: 100%;
  z-index: 1;
  top: 0;
  display: flex;
  margin-bottom: 1px;
  background: #fff;
  padding: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  vertical-align: middle;
  flex-wrap: wrap;
}

.editor .toolbar button.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
}

.editor .toolbar button.toolbar-item:disabled {
  cursor: not-allowed;
}

.editor .toolbar button.toolbar-item.spaced {
  margin-right: 2px;
}

.editor .toolbar button.toolbar-item i.format {
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 2px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}

.editor .toolbar button.toolbar-item:disabled i.format {
  opacity: 0.2;
}

.editor .toolbar button.toolbar-item.active {
  background-color: rgba(223, 232, 250, 0.3);
}

.editor .toolbar button.toolbar-item.active i {
  opacity: 1;
}

.editor .toolbar .toolbar-item:hover:not([disabled]) {
  background-color: #eee;
}

.editor .toolbar .divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
}

.editor .toolbar select.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 70px;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
}

.editor .toolbar select.code-language {
  text-transform: capitalize;
  width: 130px;
}

.editor .toolbar .toolbar-item .text {
  display: flex;
  line-height: 20px;
  width: 200px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  width: 70px;
  overflow: hidden;
  height: 20px;
  text-align: left;
}

.editor .toolbar .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}

.editor .toolbar i.chevron-down {
  margin-top: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  user-select: none;
}

.editor .toolbar i.chevron-down.inside {
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: -25px;
  margin-top: 11px;
  margin-right: 10px;
  pointer-events: none;
}

.editor i.chevron-down {
  background-color: transparent;
  background-size: contain;
  display: inline-block;
  height: 8px;
  width: 8px;
  background-image: url(../../../images/icons/chevron-down.svg);
}

.editor #block-controls button:hover {
  background-color: #efefef;
}

.editor #block-controls button:focus-visible {
  border-color: blue;
}

.editor #block-controls span.block-type {
  background-size: contain;
  display: block;
  width: 18px;
  height: 18px;
  margin: 2px;
}

.editor #block-controls span.block-type.paragraph {
  background-image: url(../../../images/icons/text-paragraph.svg);
}

.editor #block-controls span.block-type.h1 {
  background-image: url(../../../images/icons/type-h1.svg);
}

.editor #block-controls span.block-type.h2 {
  background-image: url(../../../images/icons/type-h2.svg);
}

.editor #block-controls span.block-type.quote {
  background-image: url(../../../images/icons/chat-square-quote.svg);
}

.editor #block-controls span.block-type.ul {
  background-image: url(../../../images/icons/list-ul.svg);
}

.editor #block-controls span.block-type.ol {
  background-image: url(../../../images/icons/list-ol.svg);
}

.editor #block-controls span.block-type.code {
  background-image: url(../../../images/icons/code.svg);
}

.editor .dropdown {
  z-index: 1300;
  display: block;
  position: absolute;
  box-shadow:
    0 12px 28px 0 rgba(0, 0, 0, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-width: 100px;
  min-height: 40px;
  background-color: #fff;
}

.editor .dropdown .item {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
  min-width: 268px;
}

.editor .dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.editor .dropdown .item:first-child {
  margin-top: 8px;
}

.editor .dropdown .item:last-child {
  margin-bottom: 8px;
}

.editor .dropdown .item:hover {
  background-color: #eee;
}

.editor .dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  width: 200px;
}

.editor .dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
}

.editor .link-editor {
  position: absolute;
  z-index: 100;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;
}

.editor .link-editor .link-input {
  display: block;
  width: calc(100% - 24px);
  box-sizing: border-box;
  margin: 8px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  background-color: #eee;
  font-size: 15px;
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

.editor .link-editor div.link-edit {
  background-image: url(../../../images/icons/pencil-fill.svg);
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.editor .link-editor .link-input a {
  color: rgb(33, 111, 219);
  text-decoration: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
}

.editor .link-editor .link-input a:hover {
  text-decoration: underline;
}

.editor .link-editor .button {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 6px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 2px;
}

.editor .link-editor .button.hovered {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #eee;
}

.editor .link-editor .button i,
.actions i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}

.editor i.undo {
  background-image: url(../../../images/icons/arrow-counterclockwise.svg);
}

.editor i.redo {
  background-image: url(../../../images/icons/arrow-clockwise.svg);
}

.editor .icon.paragraph {
  background-image: url(../../../images/icons/text-paragraph.svg);
}

.editor .icon.large-heading,
.icon.h1 {
  background-image: url(../../../images/icons/type-h1.svg);
}

.editor .icon.small-heading,
.icon.h2 {
  background-image: url(../../../images/icons/type-h2.svg);
}

.editor .icon.bullet-list,
.icon.ul {
  background-image: url(../../../images/icons/list-ul.svg);
}

.editor .icon.numbered-list,
.icon.ol {
  background-image: url(../../../images/icons/list-ol.svg);
}

.editor .icon.quote {
  background-image: url(../../../images/icons/chat-square-quote.svg);
}

.editor .icon.code {
  background-image: url(../../../images/icons/code.svg);
}

.editor i.bold {
  background-image: url(../../../images/icons/type-bold.svg);
}

.editor i.italic {
  background-image: url(../../../images/icons/type-italic.svg);
}

.editor i.underline {
  background-image: url(../../../images/icons/type-underline.svg);
}

.editor i.strikethrough {
  background-image: url(../../../images/icons/type-strikethrough.svg);
}

.editor i.code {
  background-image: url(../../../images/icons/code.svg);
}

.editor i.link {
  background-image: url(../../../images/icons/link.svg);
}

.editor i.left-align {
  background-image: url(../../../images/icons/text-left.svg);
}

.editor i.center-align {
  background-image: url(../../../images/icons/text-center.svg);
}

.editor i.right-align {
  background-image: url(../../../images/icons/text-right.svg);
}

.editor i.justify-align {
  background-image: url(../../../images/icons/justify.svg);
}

.editor span.editor-image {
  cursor: default;
  display: inline-block;
  position: relative;
  user-select: none;
  margin-right: 8px;
}

.editor .editor-image img {
  max-width: 100%;
  cursor: default;
}

.editor .editor-image img.focused {
  outline: 2px solid rgb(60, 132, 244);
  user-select: none;
}

.editor .editor-image img.focused.draggable {
  cursor: grab;
}

.editor .editor-image img.focused.draggable:active {
  cursor: grabbing;
}

.editor .editor-image .image-caption-container .tree-view-output {
  margin: 0;
  border-radius: 0;
}

.editor .editor-image .image-caption-container {
  display: block;
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  border-top: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  min-width: 100px;
  color: #000;
  overflow: hidden;
}

.editor .editor-image .image-caption-button {
  display: block;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 30%;
  padding: 10px;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 100px;
  color: #fff;
  cursor: pointer;
  user-select: none;
}

.editor .editor-image .image-caption-button:hover {
  background-color: rgba(60, 132, 244, 0.5);
}

.editor .editor-image .image-resizer {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: rgb(60, 132, 244);
  border: 1px solid #fff;
}

.editor .editor-image .image-resizer.image-resizer-n {
  top: -6px;
  left: 48%;
  cursor: n-resize;
}

.editor .editor-image .image-resizer.image-resizer-ne {
  top: -6px;
  right: -6px;
  cursor: ne-resize;
}

.editor .editor-image .image-resizer.image-resizer-e {
  bottom: 48%;
  right: -6px;
  cursor: e-resize;
}

.editor .editor-image .image-resizer.image-resizer-se {
  bottom: -2px;
  right: -6px;
  cursor: nwse-resize;
}

.editor .editor-image .image-resizer.image-resizer-s {
  bottom: -2px;
  left: 48%;
  cursor: s-resize;
}

.editor .editor-image .image-resizer.image-resizer-sw {
  bottom: -2px;
  left: -6px;
  cursor: sw-resize;
}

.editor .editor-image .image-resizer.image-resizer-w {
  bottom: 48%;
  left: -6px;
  cursor: w-resize;
}

.editor .editor-image .image-resizer.image-resizer-nw {
  top: -6px;
  left: -6px;
  cursor: nw-resize;
}

.emoji {
  color: transparent;
  caret-color: rgb(5, 5, 5);
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin: 0 -1px;
}

.emoji-inner {
  padding: 0 0.15em;
}

.editor .emoji-inner::selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.editor .emoji-inner::moz-selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.editor .emoji.happysmile {
  background-image: url(../../../images/emoji/1F642.png);
}

.editor .emoji.veryhappysmile {
  background-image: url(../../../images/emoji/1F600.png);
}

.editor .emoji.unhappysmile {
  background-image: url(../../../images/emoji/1F641.png);
}

.editor .emoji.heart {
  background-image: url(../../../images/emoji/2764.png);
}

.editor .keyword {
  color: rgb(241, 118, 94);
  font-weight: bold;
}

.editor .actions {
  position: absolute;
  text-align: right;
  margin: 10px;
  bottom: 0;
  right: 0;
}

.editor .actions.tree-view {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.editor .actions i {
  background-size: contain;
  display: inline-block;
  height: 15px;
  width: 15px;
  vertical-align: -0.25em;
}

.editor .actions i.indent {
  background-image: url(../../../images/icons/indent.svg);
}

.editor .actions i.outdent {
  background-image: url(../../../images/icons/outdent.svg);
}

.editor .actions i.lock {
  background-image: url(../../../images/icons/lock-fill.svg);
}

.editor .actions i.image {
  background-image: url(../../../images/icons/file-image.svg);
}

.editor .actions i.table {
  background-image: url(../../../images/icons/table.svg);
}

.editor .actions i.unlock {
  background-image: url(../../../images/icons/lock.svg);
}

.editor .actions i.left-align {
  background-image: url(../../../images/icons/text-left.svg);
}

.editor .actions i.center-align {
  background-image: url(../../../images/icons/text-center.svg);
}

.editor .actions i.right-align {
  background-image: url(../../../images/icons/text-right.svg);
}

.editor .actions i.justify-align {
  background-image: url(../../../images/icons/justify.svg);
}

.editor .actions i.disconnect {
  background-image: url(../../../images/icons/plug.svg);
}

.editor .actions i.connect {
  background-image: url(../../../images/icons/plug-fill.svg);
}