/* Better to follow the sequence of property*/
/* Ex: @apply <display> <alignments> <position> <h/w/> <bg> <border> <font - size, weight, color, align, ...so on> 
<p/m/spacing> <shadow> <animation> */

.login-signup-wrapper {
  @apply flex items-center justify-center min-h-screen p-5 mx-auto my-0;
}

/* Change auto fill input color  */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px white inset;
  /* -webkit-text-fill-color: #444444; */
}

/* Image Css  */
.img img {
  @apply w-full h-full;
}

.img-cover img {
  @apply object-cover;
}

.img-contain img {
  @apply object-contain;
}

/* Card general styling class */
.card {
  @apply w-full p-5 bg-white rounded drop-shadow;
}

/* Title header text style for both card and page */
.card-page-title {
  @apply mb-2 text-lg font-semibold sm:text-2xl sm:mb-4;
}

.card-page-info {
  @apply mb-2 text-sm font-normal break-words sm:text-base sm:mb-5;
}

/* To add unique(uni) gap between any of 2 element */
.mt-uni-gap {
  @apply mt-2 sm:mt-4;
}

.input-fields-wrapper {
  @apply w-full;
}

.input-fields-wrapper label {
  @apply block text-black text-base font-medium mb-0.5;
}

.input-style {
  @apply h-12 w-full border border-select-grey rounded outline-none focus:border-theme font-medium py-px pl-3.5 pr-0.5 disabled:cursor-not-allowed disabled:bg-gray-100;
}

.input-fields-wrapper textarea {
  @apply h-full pt-2;
}

/* .styled-select textarea {
  @apply h-40 py-px pt-2 pl-3.5 pr-0.5 bg-[#f9fbfc];
} */

.input-err-style {
  @apply border-danger;
}

.err-tag,
.input-fields-wrapper .err-tag {
  @apply block h-5 text-xs sm:text-sm text-right text-danger;
}

.success-badge {
  @apply bg-success/10 text-success font-medium !important;
}

.danger-badge {
  @apply bg-danger/10 text-danger font-medium !important;
}

.secondary-badge {
  @apply bg-secondary/10 text-secondary font-medium !important;
}

.primary-badge {
  @apply bg-primary/10 text-primary font-medium !important;
}

.warning-badge {
  @apply bg-warning/10 text-warning font-medium !important;
}

/*  */
/*  */
/*  */
/*  */
/* =========================================== Replacing Work ==================================================== */
/*  */
/*  */
/*  */
/*  */

/* Custom scrollbar design css */
.custom-scrollbar::-webkit-scrollbar {
  @apply w-[5px] h-[5px] bg-slate-200;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-[#9abfff];
}

.MuiDialog-container .MuiDialog-paper::-webkit-scrollbar {
  @apply w-[5px] h-[5px] bg-slate-200;
}

.MuiDialog-container .MuiDialog-paper::-webkit-scrollbar-thumb {
  @apply bg-[#9abfff];
}

/** Mui Autocomplete DropDwon Scrollbar */
.MuiAutocomplete-listbox::-webkit-scrollbar {
  @apply w-[5px] h-[5px] bg-slate-200;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
  @apply bg-[#9abfff];
}

/** Mui Table Scrollbar */
.MuiTableContainer-root::-webkit-scrollbar {
  @apply w-[5px] h-[5px] bg-slate-200;
}

.MuiTableContainer-root::-webkit-scrollbar-thumb {
  @apply bg-[#9abfff];
}

.dr-picker-input {
  @apply h-12 w-full border-select-grey rounded focus:border-theme focus-visible:outline-0 focus-visible:outline-none focus-visible:outline-offset-0 focus:outline-0 focus:outline-offset-[unset] text-base placeholder:text-gray-400 font-medium py-2.5 pl-4 pr-3 transition-all duration-300;
}

/* ===================== Need to refactor below classes if possible ===================== */
.flex-center {
  @apply flex items-center justify-center;
}

.flex-between {
  @apply flex items-center justify-between;
}

/* Select Menu */
/* .MuiMenu-list li {
  @apply text-base pt-3 px-2.5 pb-3;
}

ul.MuiMenu-list {
  @apply py-0;
} */
.text-danger .input-style {
  @apply border-danger;
}

.tableHead {
  @apply flex items-center;
}

.tableHead img {
  @apply ml-1;
}

/* modal */
.modalTitle {
  @apply font-figtree flex justify-between items-center font-medium text-xl leading-6 text-black border border-[#71757b4d];
}

/* Select input mui */
/* .styled-select {
  @apply w-full pt-1;
} */

/* .styled-select .MuiInputBase-root {
  @apply bg-[#f9fbfc] w-full text-[#71757B];
} */

/* .styled-select .MuiInputBase-root fieldset {
  @apply border-[#71757b4d];
} */

/* .error-style .styled-select .MuiInputBase-root fieldset {
  @apply border-danger -top-[11px];
} */

.MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline,
.MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root:hover fieldset.MuiOutlinedInput-notchedOutline {
  @apply border-select-grey border-[1px];
}

.MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
  @apply border-[1px] border-theme;
}

.detailPageTitle {
  @apply text-base font-semibold text-[#71757B];
}

/* .MuiInputBase-inputMultiline {
  @apply !h-auto;
} */
.autocomplete_max_height .css-3boulj-MuiInputBase-root-MuiOutlinedInput-root {
  min-height: 3rem !important;
  max-height: none !important;
}