.related-jobs-check-box .checkbox {
  @apply w-[20px] h-[20px];
}

.deadlineWorkflow .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary {
  @apply bg-[#f9fbfc] rounded-lg;
}

.deadlineWorkflow .menuiteminputcolor441 {
  @apply md:w-[546px] sm:w-[440px] h-[56px] border border-[#ddd];
}

.selectAndRemoveRight .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary {
  @apply bg-[#f9fbfc] rounded-lg;
}

.selectAndRemoveRight .menuiteminputcolor441 {
  @apply w-[546px] h-[56px] border border-[#ddd];
}
